@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;500;600;400;800;900&display=swap');

:root{
  --light-pink : #eaa7ad;
  --medium-pink : #d2215f;
  --lighter-gray : #e4eded;
  --lighter-gray-rgb : 248,249,250;
  --light-gray : #748487;
  --medium-gray : #516266;
  --medium-blue : #13a9b0;
  --dark-blue : #002f43;
  --default-font : 'Montserrat', sans-serif;
  --border-radius : 0;
}

/* Generals */
body{
  font-family: var(--default-font);
  font-weight: 400;
}

h1, h2, h3{
  font-weight: 500;
  letter-spacing: 3px;
  margin-bottom: 1.25rem;
}

.jumbo{
  font-weight: 800;
  transition: ease-in-out all .25s;
}

h1.jumbo{
  font-size: 4.5rem;
}

.nav-link.jumbo{

  font-size: 1.35rem;
}

.offcanvas-body a{
  color: var(--medium-gray)
}

.offcanvas-body a:hover{
  color: var(--medium-blue)
}

p, label, footer a{
  font-size: 1.4rem;
  letter-spacing: 1px;
  text-align: justify;
}

img{
  max-width: 100%;
}

a{
  text-decoration: none;
}

header.fixed {
  position: fixed;
  top: 0;
  width: 100%;
}

/* Fonts */

.light-pink, .pink {
  color: var(--light-pink);
}

.medium-pink{
  color: var(--medium-pink);
}

.lighter-gray{
  color: var(--lighter-gray) ;
}

.light-gray{
  color: var(--light-gray) ;
}

.medium-gray{
  color: var(--medium-gray) ;
}

.medium-blue{
  color: var(--medium-blue) ;
}

.blue_hover:hover{
  color: var(--medium-blue) ;
}

.dark-blue{
  color: var(--dark-blue) ;
}

.bold {
  font-weight: 800;
}

.btn-light{
  background-color:#fff !important;
  border:none !important;
}

.shadow-white{
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
}

.shadow-white-lg{
  text-shadow: 0 2px 0 rgba(255, 255, 255, 0.9);
}

.shadow-black{
  text-shadow: 0 3px 0 rgba(0, 0, 0, 0.7);
}

.shadow-black-sm{
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.7);
}

.shadow-medium-sm{
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
}

/* Background colors */
.bg-light {
  background-color: var(--lighter-gray);
}

.bg-dark-blue{
  background-color: var(--dark-blue) ;
}

.bg-medium-pink, .bg-primary{
  background-color: var(--medium-pink) !important;
}

.bg-medium-blue, .bg-info{
  background-color: var(--medium-blue) !important;
}

.bg-medium-blue, .bg-success{
  background-color: var(--dark-blue) !important;
}

.nav-link.active{
  color: var(--medium-pink);
}

.nav-link{
  color: var(--gray-pink);
}

.white-transparent-gardient{
  background: -moz-linear-gradient(top,  rgba(255,255,255,1) 10%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 10%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(255,255,255,1) 10%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
  height:2.2rem !important;
  margin-bottom:-2.2rem !important;
  position: relative;
  z-index: 3;
}

.circle-bg-transparent{
  background: -moz-radial-gradient(center, ellipse cover,  rgba(var(--lighter-gray-rgb),0) 0%, rgba(var(--lighter-gray-rgb),1) 60%, rgba(var(--lighter-gray-rgb),1) 100%); /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover,  rgba(var(--lighter-gray-rgb),0) 0%, rgba(var(--lighter-gray-rgb),1) 60%,rgba(var(--lighter-gray-rgb),1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center,  rgba(var(--lighter-gray-rgb),0) 0%, rgba(var(--lighter-gray-rgb),1) 60%,rgba(var(--lighter-gray-rgb),1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

/* Forms and Inputs */

.form-control{
  border-radius: var(--border-radius);
  background:transparent;
  border:2px solid var(--light-gray);
  padding:12px 27px;
}

/* Buttons */

.btn-primary{
  color: #fff !important;
  border-color: var(--medium-pink);
  background-color: var(--medium-pink);
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
  border-color: var(--medium-blue);
  background-color: var(--medium-blue);
}

.btn-outline-primary{
    color: var(--medium-pink);
    border-color: var(--medium-pink);
    border-radius: var(--border-radius);
}

.btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary:focus {
  background-color: var(--medium-pink);
  border-color: var(--medium-pink);
}


/* Positions and Transformations*/
.bottom{
  bottom:0;
}

.rot90{
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.body{
  opacity: 0;
}

.round-circle{
  border-radius: 50%;
}

/* Elements */

.mosaic-portfolio{
  overflow: hidden;
  width: 100%;
  transition: all .5s;
  z-index: 0;
}

.progress {
  height: 0.5rem;
}

.mosaic-portfolio::before{
  content:'';
  padding-top: 100%;
  float: left;
}

.img-mosaic{
  transition: all .5s;
}

.mosaic-portfolio:hover .img-mosaic{
  transform: scale(1.05);
  z-index: 2;
}

.mosaic-portfolio .btn{
  opacity: 0;
  transition: all .5s;
  transform: scale(.25);
  z-index: 4;
  position: absolute;
  width:150px;
  height: 150px;
  line-height: 130px;
  top: 0; left: 0; bottom: 0; right: 0;

  margin: auto;
}

.mosaic-portfolio:hover .btn{
  opacity: 1;
  transform: scale(1);
}

.bg-background-image{
  background-position: top center;
  background-size: cover;
}

.transparent-image-top{
  position: absolute;
  z-index: 3;
  background-color: var(--dark-blue);
  width: 100%;
  height: 100%;
  opacity: 0.85;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
}

.transparent-image-top:hover{
  opacity: 0.65;
}

.bg-dark-blue a{
  color:var(--medium-blue);
}

#mainBanner{
  overflow: hidden;
}

.carousel-caption{
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: var(--medium-pink);
}

/* Animations */
.pulse {
  animation: animated-cursor ease-in-out 1500ms infinite;
}

@keyframes animated-cursor{
  0% {opacity:1;}
  60% {opacity:1;}
  65% {opacity:0;}
  100% {opacity:0;}
}

.fade-in {
  animation: fadeIn .5s;
  -webkit-animation: fadeIn .5s;
  -moz-animation: fadeIn .5s;
  -o-animation: fadeIn .5s;
  -ms-animation: fadeIn .5s;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

.fade-out {
  animation: fadeOut 1.5s;
  -webkit-animation: fadeOut 1.5s;
  -moz-animation: fadeOut 1.5s;
  -o-animation: fadeOut 1.5s;
  -ms-animation: fadeOut 1.5s;
}

@keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-moz-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-webkit-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-o-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-ms-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}


.bounce {
	-moz-animation: bounce 3s infinite;
	-webkit-animation: bounce 3s infinite;
	animation: bounce 3s infinite;
}

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.boing_hover:hover{
  animation: boing .3s;
  -webkit-animation: boing .3s;
  -moz-animation: boing .3s;
  -o-animation: boing .3s;
  -ms-animation: boing .3s;
}

.boing_hover_slow:hover{
  animation: boing_slow .5s;
  -webkit-animation: boing_slow .5s;
  -moz-animation: boing_slow .5s;
  -o-animation: boing_slow .5s;
  -ms-animation: boing_slow .5s;
}

.boing{
  animation: boing 1.5s;
  -webkit-animation: boing 1.5s;
  -moz-animation: boing 1.5s;
  -o-animation: boing 1.5s;
  -ms-animation: boing 1.5s;
}

@keyframes boing {
  15%, 40%, 75%, 100% {
      transform-origin: center center;
  }
  15% {
      transform: scale(1.5, 1.31);
  }
  40% {
      transform: scale(0.95, 0.95);
  }
  75% {
      transform: scale(1.25, 1.1);
  }
  100% {
      transform: scale(1, 1);
  }
}

@keyframes boing_slow {
  15%, 40%, 75%, 100% {
      transform-origin: left center;
  }
  15% {
      transform: scale(1.5, 1.31);
  }
  40% {
      transform: scale(1.05, 1.05);
  }
  75% {
      transform: scale(1.15, 1.1);
  }
  100% {
      transform: scale(1, 1);
  }
}

@media (max-width: 480px) {
  h1.jumbo {
    font-size: 3.5rem;
  }

  p, label, footer a {
    font-size: 1.1rem;
    letter-spacing: 1px;
    text-align: justify;
  }

}